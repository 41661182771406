import React from 'react';
import { Typography, Button, Avatar,  } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import API from '../API';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
const NAMES = ["Motorsiklet", "Sedan", "Doblo", "Minivan","Panelvan","Kamyonet"];
export default class ApplicationsScreen extends React.Component {

    constructor() {
        super();
        this.state = {applications: []}
    }

    componentDidMount() {
        API.fetch('getapplications').then((e) => {
            if(!e.error) this.setState({applications: e});
        })
    }
    render() {
        return <div>

<Badge style={{marginTop:50,padding:0}}>
                <div style={{padding:24}}>
                <Typography variant='h6'>Başvurular</Typography>
                
                </div>
            <DataGrid
                
                disableSelectionOnClick={true}
                autoHeight
                rows={this.state.applications.map((x, i) => {return {id: i, "AD SOYAD": x, "TARİH": dateFromObjectId(x._id).toLocaleString("tr-TR"), "DURUM": x.status, "TELEFON": x.tel,"ŞEHİR": API.getCityName(x.city), "ARAÇ": NAMES[x.type]}})}
                columns={[{field: "AD SOYAD",flex:1, renderCell: (params) => {
                    
                    return <div style={{display:"flex",alignItems:"center"}}>
                        {/* <Avatar src={""+params.value.profileURL+""}  /> */}
                        
                        <div style={{marginLeft:0}}>
                            <Typography variant="body1">{params.value.firstName + " " + params.value.lastName}</Typography>
                            <Typography variant="body2">{params.value.email}</Typography>
                        </div>
                    </div>
                }}, {field: "TARİH",flex:1} , {field: "DURUM",flex:1, renderCell: (params) => {
                    
                    return <div style={{paddingTop:5, paddingBottom:5, paddingLeft:10, paddingRight:10,borderRadius:20,width:"fit-content",height:"fit-content", backgroundColor:(params.value ==0)?"orange":"green"}}>
                    <Typography sx={{color:"white",fontWeight:"700"}} variant='body2'>{params.value == 0?"BAŞVURU YAPILIYOR":"ONAY BEKLİYOR"}</Typography>
                </div>
                }}, {field: "TELEFON",flex:1},{field: "ŞEHİR",flex:1},{field:"ARAÇ", flex:1},{field: " ", renderCell: (params) => {
                    return <a href={'../application/'+ params.row['AD SOYAD']._id}><Button><ArrowForwardIcon /></Button></a>
                    // return <Link to={'/application/' + params.row['AD SOYAD']._id} ><Button><ArrowForwardIcon /></Button></Link>
                }}]}
                pageSize={50}
                rowsPerPageOptions={[50]}
                
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            />
            </Badge>
        </div>
    }
}

var dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}